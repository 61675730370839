import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo, BlogPostJsonLd } from "gatsby-plugin-next-seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import PostMeta from "../components/PostMeta";

const BlogPostPage = ({ data }) => {
  const { contentfulBlogPost } = data;
  const { title, body, id, createdDate, seoComponent, image } = contentfulBlogPost;
  const featuredImage = getImage(image);

  return (
    <Layout
      bodyClass={`post-template-default single single-post postid-${id} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo
        title={seoComponent?.seoTitle}
        description={seoComponent?.seoDescription?.seoDescription}
        canonical={`https://american-casinos.com/${seoComponent?.seoSlug}/`}
      />
      <BlogPostJsonLd
        url={`https://american-casinos.com/${seoComponent?.seoSlug}/`}
        title={title}
        images={[image.file.url]}
        authorName="American Casinos Team"
        datePublished={createdDate}
        description={seoComponent?.seoDescription?.seoDescription}
      />
      <article
        className={`post-${id} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`post-${id}`}
      >
        <header className="entry-header has-text-align-left header-footer-group">
          {image !== null && <GatsbyImage image={featuredImage} alt={title} />}
          <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: title }} />
        </header>

        <div className="post-inner thin">
          <div className="post-meta">
            <PostMeta title={title} date={createdDate} />
          </div>
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }} />
        </div>
      </article>
    </Layout>
  );
};

export default BlogPostPage;

export const query = graphql`
  query post($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      id
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImage(width: 980, quality: 70, formats: [AUTO, WEBP], placeholder: NONE)
        file {
          url
        }
      }
      title
      seoComponent {
        seoTitle
        seoDescription {
          seoDescription
        }
        seoSlug
      }
      createdDate(formatString: "DD.MM.YYYY")
    }
  }
`;
