import React from "react";
import DateIcon from "../assets/svg/date.inline.svg";

const PostMeta = ({ date }) => {
  return (
    <div className="post-meta-wrapper post-meta-single post-meta-single-top">
      <ul className="post-meta">
        <li className="post-date meta-wrapper">
          <span className="meta-icon">
            <span className="screen-reader-text">Post date</span>
          </span>
          <span className="meta-text">
            <DateIcon /> {date}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default PostMeta;
